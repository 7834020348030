<template>
  <v-app>
    <div class="row">
      <div class="col-md-4">
        <div class="d-flex justify-content-center align-items-center image-container">
          <div style="height: 350px; width: 350px; position: relative">
            <img
              class="image"
              :src="[uploadedImageUrl == '' ? form.image : uploadedImageUrl]"
              @load="onImageLoad()"
            />
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <b-form
          @submit.stop.prevent="formOnSubmit"
          enctype="multipart/form-data"
        >
          <!-- Name input -->
          <b-form-group
            id="input-group-title"
            label="Nama:"
            label-for="input-title"
          >
            <b-form-input
              id="input-title"
              v-model="form.title"
              placeholder="Nama Pengumuman"
            ></b-form-input>
            <small class="text-danger">{{ error.title }}</small>
          </b-form-group>

          <!-- Category Announcement Input-->
          <b-form-group
            id="input-group-announcement-category"
            label="Kategori Pengumuman:"
            label-for="input-announcement-category"
          >
            <treeselect
              v-model="form.announcement_category_id"
              :multiple="false"
              :options="announcement_categories"
              @select="setAnnouncementCategoryName"
            />
            <small class="text-danger">{{
            error.announcement_category_id
          }}</small>
          </b-form-group>

          <!-- Image -->
          <b-form-group
            id="input-group-image"
            label="Gambar:"
            label-for="input-image"
          >
            <b-form-file
              v-model="form.photo"
              placeholder="Upload Gambar"
              drop-placeholder="Drop file here..."
              @change="onFileUpload"
            ></b-form-file>
            <small class="text-danger">{{ error.image }}</small>
          </b-form-group>

          <!-- Input Start Date -->
          <b-form-group
            id="input-group-start-date"
            label="Tanggal Mulai ditampilkan:"
            label-for="input-start-date"
          >

            <v-dialog
              ref="dialog"
              v-model="modal1"
              :return-value.sync="form.start_date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">

                <b-form-input
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  id="input-birt-start-date"
                  v-model="form.start_date"
                >
                </b-form-input>
              </template>

              <v-date-picker
                locale="id"
                v-if="modal1"
                v-model="form.start_date"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal1 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(form.start_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <small class="text-danger">{{ error.start_date }}</small>
          </b-form-group>

          <!-- Input Start Date -->
          <b-form-group
            id="input-group-end-date"
            label="Tanggal Berakhir ditampilkan:"
            label-for="input-end-date"
          >

            <v-dialog
              ref="dialog_donation"
              v-model="modal2"
              :return-value.sync="form.end_date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">

                <b-form-input
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  id="input-last-donation-end-date"
                  v-model="form.end_date"
                >
                </b-form-input>
              </template>

              <v-date-picker
                locale="id"
                v-if="modal2"
                v-model="form.end_date"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal2 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog_donation.save(form.end_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <small class="text-danger">{{ error.date }}</small>
          </b-form-group>

          <!-- Content Input -->
          <b-form-group
            id="input-group-content"
            label="Konten:"
            label-for="input-content"
          >
            <div class="document-editor">
              <ckeditor
                :editor="editor"
                :config="editorConfig"
                @ready="onReady"
                v-model="form.content"
              ></ckeditor>
            </div>
            <small class="text-danger">{{ error.content }}</small>
          </b-form-group>

          <!-- <b-form-group
        id="input-group-content"
        label="Konten:"
        label-for="input-content"
      >
        <b-form-textarea
          id="input-content"
          v-model="form.content"
          rows="3"
          placeholder="Konten"
        ></b-form-textarea>
        <small class="text-danger">{{ error.content }}</small>
      </b-form-group> -->

          <!-- Submit & Cancel button -->
          <b-button
            type="submit"
            variant="primary"
          >Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="$router.push('/announcements')"
          >
            Batal
          </b-button>
        </b-form>
      </div>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      // Error
      error: {
        title: "",
        content: "",
        announcement_category_id: null,
        announcement_category_name: null,
        image: "",
      },
      announcement_categories: [],
      imgLoaded: false,
      uploadedImageUrl: "",
      // ckeditor
      editor: DecoupledEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "indent",
          "outdent",
          "|",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        alignment: {
          options: ['left', 'right', 'center', 'justify']
        },
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
      modal1: false,
      modal2: false,
    };
  },

  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },

    onImageLoad() {
      this.imgLoaded = true;
    },

    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.image = evt.target.files[0];
      console.log("image", this.form.image)
    },

    setAnnouncementCategoryName(evt) {
      console.log(evt);
      this.form.announcement_category_name = evt.label;
    },

    async getAnnoucmentCategoriesOption() {
      let response = await module.setTreeSelect("announcement-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.announcement_categories = response.data;
        this.announcement_categories.unshift({
          label: "Pilih Kategori Pengumuman",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnSubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/announcements/list");
        this.$root.$emit("updateAnnouncements");
      }
    },
  },

  mounted() {
    this.getAnnoucmentCategoriesOption();
  },
};
</script>

<style>
.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-button:not(.ck-heading_paragraph)
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl(203, 100%, 50%);
}

.document-editor
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: 0.5em;
  margin-bottom: 1.13em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
</style>